body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #0f6a5e;
  color: white;
}

.container{
  display: flex;
  flex-direction: column;
}

.content{
  min-height: 85vh;
}

.title {
  display: block;
  font-family: "Lobster", cursive;
  font-size: larger;
}

.buttons {
  margin: 0;
  text-align: center;
}

h1 {
  text-align: center;
}

.joke-container {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 25px;
}

button {
  background-color: #599991;
  cursor: pointer;
  font-weight: 800;
  padding: 10px;
  height: 50px;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.2s;
}
button:hover {
  border-color: rgba(255, 255, 255, 1);
  background-color: #fff;
  color: #267a6f;
}

@media all and (max-width: 400px) {
  button {
    display: block;
    margin: 0.2em auto;
    width: 100%;
  }
}

.modal {
  z-index: 300;
  margin: auto;
  margin-top: 3rem;
  border: 1px solid #011627;
  border-radius: 1em;
  background-color: #fdfffc;
  width: 400px;
  max-width: 95%;
  color: #011627;
  text-align: center;
}
.modal-buttons {
  padding: 1rem;
}

.btn-confirm {
  background-color: red;
}

.install {
  margin-bottom: 20px;
}

.footer {
  margin: 0;
  font-style: italic;
  text-align: center;
}

a {
  text-decoration: none;
  color: turquoise;
  text-decoration: underline;
}
